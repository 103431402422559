import React, { useEffect, useState, useRef } from 'react';
import ModelListing from '../components/ModelListing';
import { useNavigate } from 'react-router-dom';
import CharacterDetails from './CharacterDetails';
import { useLocation } from 'react-router-dom';
import Api from '../services/Api';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import { setQuestsdata, resetQuests, removeQuestsdata } from '../actions/questActions';
import { useDispatch } from 'react-redux';
import Buttons from '../components/Buttons';
import { toast } from "react-toastify";
import Slider from 'react-slick';
import { Modal } from 'react-bootstrap';
import CharacterSlider from '../components/CharacterSlider';
import CheckBox from '../components/CheckBox';
import NestListing from '../components/Nestlising';
import QuestListing from '../components/QuestLisitng';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import Icon from "react-icons-kit";
import { arrowLeft } from 'react-icons-kit/icomoon/arrowLeft'
import { arrowRight } from 'react-icons-kit/icomoon/arrowRight'

let itemImages = [
  {
    id: 1,
    img: require('../assets/categories/beliefs.png'),
    name: 'Beliefs'
  },
  {
    id: 2,
    img: require('../assets/categories/biases.png'),
    name: 'Biases'
  },
  {
    id: 3,
    img: require('../assets/categories/emotions.png'),
    name: 'Emotions'
  },
  {
    id: 4,
    img: require('../assets/categories/gratitude.png'),
    name: 'Gratitude'
  },
  {
    id: 5,
    img: require('../assets/categories/habits.png'),
    name: 'Habits'
  },
  {
    id: 6,
    img: require('../assets/categories/neurotransmitters.png'),
    name: 'Neurotransmitters'
  },
  {
    id: 7,
    img: require('../assets/categories/triggers.png'),
    name: 'Triggers'
  },
]

// let type = 'default';
// let type = 'defaultval';

const Models = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterLoading, setFilteringLoading] = useState(false)
  const [_helmet, setHelmet] = useState({ title: "models", des: 'models description' })
  const [loaded, setLoaded] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState('new');
  const [characterCategory, setCharacterCategory] = useState(false);
  const [categories, setCategories] = useState([])
  const [settings, setSettings] = useState([])
  const [addingItem, setAddingItem] = useState(false);
  const [itemsdata, setItemsData] = useState({})
  const [modal, setModel] = useState(false)
  const [itemPhoto, setItemPhoto] = useState({})
  const [termsAccepted, setTermsAcceptance] = useState(false)
  const [termsErr, setTermsError] = useState(false)
  const [quest, setQuests] = useState([])
  const [isPublic, setPublic] = useState(false)
  const [savingCharacter, setSavingCharacter] = useState(false)
  const [readmore, setReadMore] = useState(false)
  const [nestData, setNestData] = useState(false)
  const [imagesToSelect, setImagesToSelect] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [activeCategory, setActiveCategory] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slidesCount, setSlidesCount] = useState(0);
  const dispatch = useDispatch()
  const [currentSlide, setCurrentSlide] = useState(0);

  const isLogged = useSelector((state) => state.user.isLogged);
  const selected = useSelector((state) => state.quests.selected);
  const default_character = useSelector((state) => state.characotors.default_charactor);
  const sliderReffooter = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const sliderref = useRef();

  const pageRef = useRef(0)
  const hasMoreRef = useRef(false);

  let _settingsFooter = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: true,
    swipe: true,
    arrows: false,
    easing: 'ease',
  };

  let width = window.innerWidth;
  let { redirectmessage = null, redirectdata = {} } = location?.state || { redirectmessage: null, data };

  const CustomSliderButtons = ({ onClickPrevious, onClickNext, slideIndex, slidesCount }) => {
    return (
      <div className="custom-slider-buttons character_slider_button_svg" style={{ top: '75%' }}>
        <div>
          <button className="custom-prev-button" onClick={onClickPrevious}>
            <Icon icon={arrowLeft} size={12} />
          </button>
        </div>
        <div>
          <button className="custom-next-button" onClick={onClickNext}>
            <Icon icon={arrowRight} size={12} />
          </button>
        </div>
      </div>
    )
  };

  const goToPrevious = () => {
    sliderReffooter.current.slickPrev();
  };

  const goToNext = () => {
    sliderReffooter.current.slickNext();
  };


  useEffect(() => {

    let { images = {}, itemsData = {}, characterCategory } = redirectdata;

    if (Object.keys(itemsData).length && Object.keys(images).length) {
      setAddingItem(true)
      setItemsData(itemsData)
      setItemPhoto(images)
      setCharacterCategory(characterCategory || false)
    }

    // else {
    //   setItemPhoto({
    //     healthy: itemImages[0],
    //     toxic: itemImages[1],
    //     photo: itemImages[2],
    //   })
    // }
    document.documentElement.style.setProperty('--dynamic-dots-color', characterCategory?.default_character ? characterCategory?.default_character?.color : default_character?.color || "#000");
  }, [default_character, characterCategory])

  useEffect(() => {
    Api.get("images/list")
      .then((res) => {
        if (res.success) {
          setImagesToSelect(res.data);
          setItemPhoto({
            healthy: res.data[0],
            toxic: res.data[0],
            photo: res.data[0],
          })
        }
      })
      .catch((err) => {
      })
  }, [])

  const closeTermsModel = () => {
    setModel(false);
  }
  const onTermsChange = () => {
    setTermsError(false)
    setTermsAcceptance(!termsAccepted);
  }
  const onPublicChange = () => {
    setPublic(!isPublic);
  }
  const handleAddItem = () => {
    if (!termsAccepted) {
      setTermsError(true)
      return
    }
    setSubmitting(true);

    Api.post('images/save', {
      "image_id": { "default": itemPhoto?.thought?.id, "healthy": itemPhoto?.healthy?.id, "toxic": itemPhoto?.toxic?.id },
      "category_id": characterCategory.id,
      "description": { "default": itemsdata.thought, "healthy": itemsdata.healthy, "toxic": itemsdata.toxic },
      "is_public": isPublic ? 1 : 0
    })
      .then((res) => {
        if (res.success) {
          toast.success("Added successfully!")
        }
      })
      .catch((err) => {
        toast.error(err.message || "Some Error Occured")

      })
      .finally(() => {
        setSubmitting(false)
        setAddingItem(false)
        setItemsData({})
        setModel(false)
        setPublic(false);
        setTermsAcceptance(false)
      })

  }


  const handleChange = (key, val) => {
    let data = { ...itemsdata };

    data[key] = val

    if (data[key].trim() == "") {
      delete data[key]
    }
    setItemsData(data)
  }

  let _settings = {
    infinite: false,
    dots: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    draggable: true,
    swipe: true,
    arrows: false,
    easing: 'ease',
    responsive: [
      {
        breakpoint: 1386,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };


  useEffect(() => {
    setSettings(_settings)
  }, [window.innerWidth])


  const details = (e, ele) => {
    ele.preventDefault()
    navigate(`/models/${(e?.seo || 'model')}`)

    // if (isLogged) {
    //   navigate(`/models/${(e?.seo || 'model')}`)
    // } else {
    //   navigate((e?.seo || 'model'))
    // }
  }

  const onSort = (id, sort) => {
    let _data = [...data]
    let index = _data.findIndex(item => item.id == id)

    if ((index == 0 && sort == "up") || (index == (_data.length - 1) && sort == 'down')) {
      toast.error(`No item is ${sort == "down" ? "below" : "above"}!`)
      return
    }


    Api.get("characters/set-order/" + id + "/" + sort)
      .then((res) => {
        if (res.success) {
          if (sort == "up") {
            let before = _data[index]
            _data[index] = _data[index - 1]
            _data[index - 1] = before
          } else {
            let before = _data[index]

            _data[index] = _data[index + 1]
            _data[index + 1] = before
          }
          setData(_data)
        } else {
          toast.error(res?.message || "Some Error occured!")
        }
      })
      .catch((err) => {
        toast.error(err?.message || "Some Error occured!")
      })
  }

  const handleaddItem = () => {
    setAddingItem(true)
  }

  const handleItemImage = () => {
    setModel('images');

  }
  const submit_item = () => {

    if (!isLogged) {
      navigate('/login', { state: { from: "/", redirectdata: { images: itemPhoto, itemsData: itemsdata, characterCategory } } })
      // navigate('/login', { state: { from: location.pathname, redirectmessage: message } })
    }

    if (characterCategory.type != '2') {
      if (itemsdata?.thought && itemsdata.thought.trim() == "") {
        toast.error('Field cannot be empty!')
        return
      } else {
        // toast.error('Please Enter Both Toxic and Healthy Items!')
        setModel('submit');
        return
      }
    }
    if (!itemsdata?.toxic || !itemsdata?.healthy) {
      toast.error('Please Enter Both Toxic and Healthy Items!')
      return
    }
    setModel('submit');
  }

  const selectItemPhoto = (k, v) => {

    // if (type == 'default') {
    let data = { ...itemPhoto }
    data[k] = v;
    setItemPhoto(data)
    // } else {
    //   let data = { ...itemPhoto }
    //   data['photo'] = v;
    //   setItemPhoto(data)
    // }

  }

  const handle_photos = () => {

    setModel(false)
  }

  const updateLike = (e) => {

    if (selected.find((item) => item.id == e.id)) {
      selected.filter((item) => item.id != e.id)
      dispatch(removeQuestsdata(e))
    } else {
      dispatch(setQuestsdata(e))
    }

    // Api.post('characters/like',
    //   {
    //     type: e.liked ? 0 : 1,
    //     character_id: JSON.stringify(e.id)
    //   })
    //   .then((res) => {
    //     if (res.success) {
    //       let _data = []
    //       data.map((ele) => {
    //         if (ele.id == e.id) {
    //           ele['liked'] = e.liked ? false : true
    //           _data.push(ele)
    //         } else {
    //           _data.push(ele)
    //         }
    //       })
    //       setData(_data)
    //     } else {

    //     }
    //   })
    //   .catch(() => {

    //   })
  }


  useEffect(() => {
    setLoading(true)
    setHelmet({ title: "models", des: 'models description' })
    let url = isLogged ? 'characters/list' : 'characters/public/list'
    let characters = Api.post(url)
    let _categories = Api.get('chat-category/list ')

    Promise.all([characters, _categories])
      // Promise.all([characters, categories])
      .then((res) => {
        if (res[0].success) {
          setData(res[0].data)
        } else {
          setData([])
        }
        if (res[1].success) {
          setCategories(res[1].data);
          setCharacterCategory(res[1].data[0], 0)
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setData([])
      })
      .finally(() => {
        setLoading(false)
      })

    // return () => dispatch(resetQuests())
  }, [location])

  useEffect(() => {
    if (categories.length > 0) {
      onCategorySelect(categories[0], 0)
    }
  }, [categories])

  useEffect(() => {
    setLoaded(false)
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [_helmet])

  const onFilterChange = (e) => {
    setSelectedFilter(e.target.value)
    setFilteringLoading(true)

    let url = characterCategory ? `chat-category/detail/${characterCategory.id}${selectedFilter && ("?filter=" + e.target.value)}` : `characters${isLogged ? "" : '/public'}/list?filter=${e.target.value}`
    Api.post(url)
      .then((res) => {
        if (res.success) {
          if (characterCategory) {
            setData(res.data[0].character)
          } else {
            setData(res.data)
          }
        } else {
        }
      })
      .finally(() => {
        setFilteringLoading(false)
      })
  }


  // useEffect(() => {
  //   setLoading(true);
  //   // let _characterItems = localStorage.getItem("charactersItems")
  //   // if (_characterItems != null) {
  //   //     setCharacterItems(JSON.parse(_characterItems))
  //   // }

  //   Api.post('chat-category/list')
  //     .then((res) => {
  //       if (res.success) {
  //         setCategories(res.data)
  //         // scrollToTop()
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  // }, [])

  const onCategorySelect = (item, index, type = 'C') => {

    if (type == 'S' && 0) { //       remove    || 0  condition for default functionality
      return;
    }

    let winwid = window.screen.width;
    let _itemsperslide = winwid > 1025 ? 5 : winwid < 1024 ? 4 : winwid < 768 ? 3 : winwid < 480 ? 2 : 2

    if(categories.length >_itemsperslide ){
      sliderref?.current?.slickGoTo( Math.floor(index +1 /_itemsperslide))
    }

    // if( currentSlide === null )

    setCurrentSlide(index || 0);
    // setActiveCategory(index)
    console.log('ttttttttttt')
    // if (characterCategory.id == item.id) {
      // setCharacterCategory(false);
      // let url = isLogged ? 'characters/list' : 'characters/public/list'
      // Api.post(url)
      //   .then((res) => {
      //     if (res.success) {
      //       setData(res?.data || [])
      //     } else {
      //       setData([])
      //     }
      //   })
      // let categories = Api.get('categories');

    // } else {
      setAddingItem(false);
      setItemPhoto({
        healthy: imagesToSelect[0],
        toxic: imagesToSelect[0],
        photo: imagesToSelect[0],
      })

      setItemsData({})
      item.index = index;
      setCharacterCategory(item)

      if (item.is_level == 'Y') {

        Api.get(`chat/quests?limit=2&page=1`)
          .then((res) => {
            if (res.success) {
              if (res?.data) {
                if (res.data.next) {
                  pageRef.current = 2
                }
                hasMoreRef.current = res.data.next
                setQuests(res.data.list)
              }
              setNestData(res?.data?.nest || [])
            } else {
              hasMoreRef.current = false
            }
          }).catch(() => {
            hasMoreRef.current = false
          })
          .finally(() => {
            setLoading(false)
          })
      } else {

        Api.get(`chat-category/detail/${item.id}${selectedFilter && ("?filter=" + selectedFilter)} `)
          .then((res) => {
            if (res.success) {
              setData(res?.data[0].character || [])
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    // }
  }

  const loadMore = () => {
    // if (loading) return
    Api.get(`chat/quests?limit=2&page=${pageRef.current}&filter=${selectedFilter}`)
      .then((res) => {
        if (res.success) {
          hasMoreRef.current = res.data.next
          let _data = [...quest, ...res.data.list]
          setQuests(_data)
          pageRef.current++
        } else {
          hasMoreRef.current = false
        }
      })
      .catch(() => {
        hasMoreRef.current = false
      })
  }

  const onSlideChange = (current, next) => {
    if (categories[activeCategory + 1]) {
      setCharacterCategory(categories[activeCategory + 1])
      setActiveCategory(activeCategory + 1)
      onCategorySelect(categories[activeCategory + 1])
    } else if (categories[0]) {
      setCharacterCategory(categories[0])
      setActiveCategory(0)
      onCategorySelect(categories[0])
    }
  }

  const hasItemImages = () => {
    let res = false
    imagesToSelect.map((item)=> {
      if(item.category_id.includes(characterCategory.id)){
        res = true
      }
    })

    return res
  }

  // useEffect(()=> {
  //   if(categories.length){
  //     setInterval(()=> {
  //       changeCategory()
  //     }, 5000)
  //   }
  // },[categories])


  return (
    <div className='main_outer_container'>
      <Modal
        show={modal}
        centered
      >
        {modal == "images" ?
          <>
            <Modal.Header>
              <h4 style={{ fontFamily: 'Dimbo' }}>Select Image</h4>
              <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} style={{ cursor: 'pointer' }} />
              </div>
            </Modal.Header>
            {characterCategory.type == '2' ?
              <div className='d-flex justify-content-around'>
                <div>
                  <div className='text-center my-2' style={{ fontFamily: "Dimbo" }} >Healthy Image</div>
                  {imagesToSelect.map((item, index) => {
                    if (!item.category_id.includes(characterCategory.id)) return
                    return (
                      <div key={index} onClick={() => selectItemPhoto("healthy", item)} style={{ boxSizing: 'border-box' }} className={`rounded m-2 border border-2 ${itemPhoto?.healthy?.id == item.id ? "border-dark" : " border-white"}`}>
                        <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                      </div>)
                  }
                  )}
                </div>
                <div>
                  <div className='text-center my-2' style={{ fontFamily: "Dimbo" }} >Toxic Image</div>
                  {imagesToSelect.map((item, index) => {
                    if (!item.category_id.includes(characterCategory.id)) return
                    return (<div key={index} onClick={() => selectItemPhoto("toxic", item)} style={{ boxSizing: 'border-box' }} className={`rounded m-2 border border-2 ${itemPhoto?.toxic?.id == item.id ? "border-dark" : " border-white"}`}>
                      <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                    </div>)
                  }
                  )}
                {!hasItemImages() && <div className='text-center py-2'>No image available</div> }
                </div>
              </div>
              :
              <div>
                {imagesToSelect.map((item, index) => {
                  if (!item.category_id.includes(characterCategory.id)) return
                  else if (item.image) {
                    return (
                      <div key={index} onClick={() => selectItemPhoto("photo", item)} style={{ boxSizing: 'border-box', width: "68px" }} className={`rounded m-2 border border-2 m-auto ${itemPhoto?.photo?.id == item.id ? "border-dark" : " border-white"}`}>
                        <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                      </div>
                    )
                  }

                })}
                {!hasItemImages() && <div className='text-center py-2' >No image available</div> }
              </div>
            }
            <Modal.Footer>
              <Buttons title={'Save'} onClick={handle_photos} style={{ height: '50px', lineHeight: '50px' }} />
              {/* <Buttons title={'Create'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={savingCharacter} onClick={saveCharacter} /> */}
            </Modal.Footer>
          </>
          :
          <div>
            <form >
              <Modal.Header>
                <h4 style={{ fontFamily: 'Dimbo' }}>Add Item</h4>
                <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                  <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} style={{ cursor: 'pointer' }} />
                </div>
              </Modal.Header>
              <Modal.Body>
                {/* <h5 style={{ fontFamily: 'Dimbo' }} >Terms and conditions</h5> */}
                {termsErr && <div className="border border-danger rounded px-2 py-1 mb-2 text-danger">Please accept the Terms and Conditions!</div>}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckBox value={isPublic} onChange={onPublicChange} /> <div>Set as Public</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckBox value={termsAccepted} onChange={onTermsChange} /> <div>I accept the <a style={{ color: '#000' }} href="https://nestheads.com/term-of-use/" target='_blank'>Terms and Conditions</a>.</div>
                </div>
              </Modal.Body>
            </form>
            <Modal.Footer>
              <Buttons title={'Add Item'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={submitting} onClick={handleAddItem} />
            </Modal.Footer>
          </div>
        }
        {/* <div className='mx-4' >
          <Buttons title={'Save'} onClick={handle_photos} style={{ height: '50px', lineHeight: '50px' }} />
        </div> */}
      </Modal>
      {/* <div className='main_model_dailog_container' style={{ backgroundColor: characterCategory?.default_character ? characterCategory.default_character.color : default_character.color }}>
        <div className='model-page-dailoge'  >
          <div className='default-dialog-image-container' style={{ backgroundColor: characterCategory?.default_character ? characterCategory.default_character.color : default_character.color }} >
            <img src={characterCategory?.default_character ? characterCategory.default_character.image : default_character.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </div>
          <div style={{ margin: '0px 20px' }}>
            <div className='default-dailog-character-name' style={{ fontFamily: 'Dimbo' }}>{characterCategory?.default_character ? characterCategory.default_character.name : default_character.name}</div>
            <div className='default-dailog-character-des' style={{ fontFamily: 'Dimbo' }}>{characterCategory?.default_character ? characterCategory.default_character.description : default_character.description}</div>
          </div>
        </div>
      </div> */}
      {/* <div>TEst</div> */}
      <div >
        <div className='create_character' >
          <div className='main_model_dailog_container'>
            <CharacterSlider
              characterCategory={categories[currentSlide]?.default_character?.dialogue?.length > 0 ? categories[currentSlide] : { default_character }}
              onchangeSlides={onSlideChange}
              onGoNext={() => {
                var cIndex = currentSlide || 0;
                var nIndex = cIndex + 1;
                if (categories.length > 1) {
                  if (nIndex == categories.length)
                    nIndex = 0;

                  if (categories[nIndex]) {
                    setCurrentSlide(nIndex);
                    onCategorySelect(categories[nIndex], nIndex, 'S');
                  }
                }
                //alert('s')
              }}
            />
          </div>
        </div>
      </div>
      <div className='page_container' style={{ margin: '0px auto', }} >
        {/*<div style={{ display: 'flex', alignItems: 'center' }} >
          <div className='main_model_image_text' >This is your NEST. The most fun way to understand and re-program your HEAD.</div>
          <img className='main_model_image' src={require('../assets/map_toxic.png')} />
        </div>*/}
        <div className='' >
          {loading ?
            <div className='image_container model-list-main-loading-container' >
              <div className='models_loading_container' ></div>
              <div className='models_loading_container' ></div>
              {width >= 400 && <div className='models_loading_container'></div>}
              {width >= 768 && <div className='models_loading_container'></div>}
              {width >= 900 && <div className='models_loading_container'></div>}
            </div>
            :
            <div className='model-list-main-container' >
              <Slider {..._settings} ref={sliderref}>
                {categories.map((item, i) => (
                  <div key={i} className='model-list-outer-container' >
                    <div key={i} onClick={() => onCategorySelect(item, i)} className={`character_category_container_slider ${characterCategory.id == item.id && 'active'} ${i == currentSlide ? ' hover-active' : ""}`} >
                      <div className='image_container' >
                        <img src={item.image} width={"100%"} height={'100%'} style={{ objectFit: 'contain' }} />
                      </div>
                      <div className='text-center'>{item.name}</div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          }
        </div>
        <div className='character_listing_container' >
          <div className='charactor_filter_container' style={{ justifyContent: characterCategory.id ? 'space-between' : 'flex-end', overflowX: 'hidden' }} >
            {characterCategory.id &&
              <div data-aos="fade-right" >
                <Buttons title={characterCategory.button_text} loading={false} onClick={handleaddItem} variant={addingItem ? "primary" : "transparent"} />
              </div>
            }
            <div data-aos="fade-left" className='my-4 mx-2 text-right'>
              <select className='character_filter' value={selectedFilter} style={{ fontFamily: 'Dimbo' }} title='Filter' onChange={onFilterChange}>
                <option className='filter_options' value={'new'}>newest</option>
                <option className='filter_options' value={'name'} >name</option>
                <option className='filter_options' value={'popularity'}>popularity</option>
                {/* <option className='filter_options' style={{ display: 'none' }} selected={selectedFilter == 'default'} >default</option> */}
              </select>
            </div>
          </div>
          {addingItem &&
            (
              characterCategory.type == "2" ?
                <div className='w-75 m-auto p-2 rounded my-5' style={{ backgroundColor: '#f7f7f7', border: '2px solid darkgray' }} >
                  <div className=' w-100 md-w-75 m-auto d-flex justify-content-around ' >
                    <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Toxic " + characterCategory?.name} variant={itemsdata?.toxic ? "primary" : 'transparent'} />
                    <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Healthy " + characterCategory?.name} variant={itemsdata?.healthy ? "primary" : 'transparent'} />
                  </div>
                  <div className='d-flex mb-2' >
                    <div onClick={handleItemImage} style={{ width: '50px', height: '50px', cursor: "pointer" }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                      <img src={itemPhoto?.healthy?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                    </div>
                    <input onChange={(e) => handleChange("toxic", e.target.value)} value={itemsdata?.toxic || ""} className='border border-0 flex-1 bg-transparent text-dark w-100' placeholder='Toxic thought here' />
                  </div>
                  <div className='d-flex mb-2' >
                    <div onClick={handleItemImage} style={{ width: '50px', height: '50px', cursor: "pointer" }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                      <img src={itemPhoto?.toxic?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                    </div>
                    <input onChange={(e) => handleChange("healthy", e.target.value)} value={itemsdata?.healthy || ''} className='border border-0 flex-1 bg-transparent text-dark w-100 ' placeholder='Healthy thought here' />
                  </div>
                  <div className='w-100 d-flex justify-content-between' >
                    <div  className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                      </svg>
                    </div>
                    <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                      </svg>
                    </div>
                  </div>
                </div>
                :
                <div className='w-75 m-auto p-2 rounded my-5' style={{ backgroundColor: '#f7f7f7', border: '2px solid darkgray' }} >
                  <div className=' w-100 md-w-75 m-auto d-flex justify-content-around ' >
                    <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={characterCategory.name} variant={itemsdata?.toxic ? "primary" : 'transparent'} />
                    {/* <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Healthy Thought"} variant={itemsdata?.healthy ? "primary" : 'transparent'} /> */}
                  </div>
                  <div className='d-flex mb-2' >
                    <div onClick={handleItemImage} style={{ width: '50px', height: '50px', cursor: "pointer" }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                      <img src={itemPhoto?.photo?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                    </div>
                    <input onChange={(e) => handleChange("thought", e.target.value)} value={itemsdata?.thought || ""} className='border border-0 flex-1 bg-transparent text-dark w-100' placeholder={characterCategory.name + " here"} />
                  </div>
                  {/* <div className='d-flex mb-2' >
                    <div style={{ width: '50px', height: '50px' }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                      <img src={itemPhoto?.toxic?.img} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                    </div>
                    <input onChange={(e) => handleChange("healthy", e.target.value)} value={itemsdata?.healthy || ''} className='border border-0 flex-1 bg-transparent text-dark w-100 ' placeholder='Healthy thought here' />
                  </div> */}
                  <div className='w-100 d-flex justify-content-between' >
                    <div  className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                      </svg>
                    </div>
                    <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                      </svg>
                    </div>
                  </div>
                </div>
            )
          }
          {
            characterCategory.is_level == "Y" ?
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMoreRef.current}
                loader={<div className="loader d-flex justify-content-center align-items-center my-3" key={0}><Spinner /></div>}
              >
                {quest.map((e, i) => {
                  if (e.type == 'M') return <NestListing i={i} e={e} />
                  else return <QuestListing e={e} i={i} readmore={readmore} setReadMore={setReadMore} />
                })}
              </InfiniteScroll> :
              <ModelListing data={data} onClick={details} updateLike={updateLike} onSort={onSort} loading={loading || filterLoading} />
          }
        </div>
      </div>
      {/* <div style={{ backgroundColor: characterCategory?.default_character?.color || 'red', height: '50px', minHeight: '50px', width: "100%", display: 'flex', justifyContent: 'space-between' , padding: '20px 10px' , alignItems:'center' }} >
        <div>
          <button className="custom-prev-button" >
            <Icon icon={arrowLeft} size={8} />
          </button>
        </div>
        <div style={{ position: 'relative' , bottom: '25px'}}  >
          <img src={require('../assets/apple.png')} style={{ height: '100px', width: '100px' , objectFit:'contain' }} />
          <div style={{position: 'absolute' , bottom: '0px' ,left:'100px'}} >/Test</div>
        </div>
        <div>
          <button className="custom-next-button"  >
            <Icon icon={arrowRight} size={8} />
          </button>
        </div>
      </div> */}



        {/* ------------------------ footer slider ---------------------- */}



      {/* {
        <div className="slider-wrapper custom-footer-slider" style={{ position: 'sticky', left: '0px', right: '0px', bottom: "0px", overflow: 'visible', }}>
          <Slider {..._settingsFooter} ref={sliderReffooter} >
            {itemImages?.map((item) => {
              return (
                <div>
                  <div style={{ height: '50px', position: 'relative', marginTop: "50px", backgroundColor: characterCategory?.default_character?.color }} >
                    <div style={{ width: '50px', margin: 'auto' }}>
                      <img src={item.img} style={{ width: '50px', objectFit: 'contain', position: 'absolute', bottom: '25px' }} />
                      <div style={{ position: 'relative', bottom: '0px', left: '50px', fontFamily: 'Dimbo' }} >{item.name}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider >
          {
            itemImages?.length > 1 && <CustomSliderButtons
              onClickPrevious={goToPrevious}
              onClickNext={goToNext}
              slideIndex={slideIndex}
              slidesCount={slidesCount}
            />
          }
        </div>
      } */}
      <Footer />
    </div >
  );
};

export default Models;