import React, { useEffect, useState } from 'react'
import Buttons from "./Buttons";
import AddHabit from "./AddHabit";
import Api from '../services/Api';
import SubCategoryLoadingComponent from './SubCategoryLoadingComponent';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setTitles } from '../actions/categoriesActions';
import { Helmet } from 'react-helmet';
import ModelListing from './ModelListing';
import { useNavigate } from 'react-router-dom';
import ToggleToxicHealthy from './ToggleToxicHealthy';
import { Modal } from 'react-bootstrap';
import CheckBox from './CheckBox';
import Icon from 'react-icons-kit';
import { arrowUp } from 'react-icons-kit/icomoon/arrowUp'
import { arrowDown } from 'react-icons-kit/icomoon/arrowDown'
import {androidDelete} from 'react-icons-kit/ionicons/androidDelete'

const SubCategory = (props) => {

    const { categoryTitle } = props
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] = useState([])
    const [publicItems, setPublidItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleating, setDeleating] = useState(null)
    const categories = useSelector(state => state.category.categories)
    const isLogged = useSelector(state => state.user.isLogged)
    const [selectedFilter, setSelectedFilter] = useState('new');
    const [activetype, setActiveType] = useState('h');
    const [addingItem, setAddingItem] = useState(false)
    // const titles = useSelector(state => state.category.titles)
    const navigate = useNavigate()

    const titles = [];
    const [_helmet, sethelmet] = useState({ title: "", des: '' });
    const [character, setcharacter] = useState({})
    const [itemsdata, setItemsData] = useState([])
    const [itemPhoto, setItemPhoto] = useState([])
    const [modal, setModel] = useState(false)
    const [imagesToSelect, setImagesToSelect] = useState([])
    const [submitting, setSubmitting] = useState(false);
    const [termsErr, setTermsError] = useState(false)
    const [isPublic, setPublic] = useState(false)
    const [termsAccepted, setTermsAcceptance] = useState(false)

    const dispatch = useDispatch();

    const handleItemImage = () => {
        setModel('images');

    }

    const handleAddItem = () => {
        if (!termsAccepted) {
            setTermsError(true)
            return
        }
        setSubmitting(true);

        Api.post('images/save', {
            "image_id": { "default": itemPhoto?.thought?.id, "healthy": itemPhoto?.healthy?.id, "toxic": itemPhoto?.toxic?.id },
            "category_id": character.id,
            "description": { "default": itemsdata.thought, "healthy": itemsdata.healthy, "toxic": itemsdata.toxic },
            "is_public": isPublic ? 1 : 0
        })
            .then((res) => {
                if (res.success) {
                    // console.log(itemPhoto)
                   
                    // if (character?.type == 2) {
                    //     setData(prev => [{ description: { healthy: itemsdata.healthy, toxic: itemsdata.toxic }, toxic_images: [{ image: itemPhoto.healthy.image },], healthy_images: [{ image: itemPhoto.toxic.image }] }, ...prev])
                    // } else {
                    //     setData(prev => [{ description: { default: itemsdata.thought }, default_image: { "image": itemPhoto.photo.image } }, ...prev])
                    // }
                    setData(prev => [...prev , res.data])

                    toast.success("Added successfully!")
                }
            })
            .catch((err) => {
                toast.error(err.message || "Some Error Occured")

            })
            .finally(() => {
                setSubmitting(false)
                setAddingItem(false)
                setItemsData({})
                setModel(false)
                setPublic(false);
                setTermsAcceptance(false)
            })

    }

    const closeTermsModel = () => {
        setModel(false)
    }
    const handleChange = (key, val) => {
        let data = { ...itemsdata };

        data[key] = val

        if (data[key].trim() == "") {
            delete data[key]
        }
        setItemsData(data)
    }


    const submit_item = () => {

        if (!isLogged) {
            navigate('/login', { state: { from: "/", redirectdata: { images: itemPhoto, itemsData: itemsdata, character } } })
            // navigate('/login', { state: { from: location.pathname, redirectmessage: message } })
        }

        if (character?.type != '2') {
            if (itemsdata?.thought && itemsdata.thought.trim() == "") {
                toast.error('Field cannot be empty!')
                return
            } else {
                // toast.error('Please Enter Both Toxic and Healthy Items!')
                setModel('submit');
                return
            }
        }
        if (!itemsdata?.toxic || !itemsdata?.healthy) {
            toast.error('Please Enter Both Toxic and Healthy Items!')
            return
        }
        setModel('submit');
    }


    useEffect(() => {
        if (parseInt(categoryTitle)) {

            sethelmet({ title: "Nestheads - " + categoryTitle.split("-")[1] });
            let parsedtitle = categoryTitle.replace("%20", "")
            // console.log(categoryTitle)
            let character_id = categories.filter((e) => {
                // console.log(categoryTitle.split("-")[1].toLowerCase())
                // console.log(e.name.toLowerCase().includes(parsedtitle.split("-")[1].toLowerCase()))
                return e.name.toLowerCase().includes(parsedtitle.split("-")[1].toLowerCase())
            })
            // console.log(character_id)
            setcharacter(character_id[0])
            setLoading(true);
            // Api.get('user-category/detail/' + parseInt(categoryTitle))
            //     .then((res) => {
            //         if (res.success) {
            //             setData(res.data)
            //         }
            //     })
            //     .finally(() => {
            //         setLoading(false)
            //     })

            Api.get('chat-category/item/' + parseInt(categoryTitle))
                .then((res) => {
                    if (res.success) {
                        setData(res.data.All)
                        setPublidItems(res.data.Approved)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [categoryTitle, categories])
    useEffect(() => {
        Api.get("images/list")
            .then((res) => {
                if (res.success) {
                    setImagesToSelect(res.data)
                    setItemPhoto({
                        healthy: res.data[0],
                        toxic: res.data[0],
                        photo: res.data[0],
                    })
                }
            })
            .catch((err) => {

            })
    }, [])
    const onConfirm = (res) => {
        // console.log(res)
        setShowPopup(false)
        if (!res) {
            return
        } else {
            let character_id = categories.filter((e) => e.name.toLowerCase() == categoryTitle.toLowerCase())
            // console.log(character_id)

            let datas = {
                "category_id": character_id[0].id,
                "category_name": categoryTitle,
                "chat_title": res
            }
            Api.post("title/add", datas)
                .then((res) => {
                    if (res.success) {
                        let newData = [...titles, res.data]
                        dispatch(setTitles(newData))
                        toast.success("New " + categoryTitle + " created!")
                    } else {
                        toast.error(res.message || "Some error occurred!")
                    }
                })
                .catch((err) => {

                })
                .finally(() => {

                })
        }
    }

    const handleDelete = (id) => {
        setDeleating(id)
        Api.get("chat-category/item-delete/" + id)
            // Api.post("title/delete/" + id)
            .then((res) => {
                if (res.success) {
                    let _data = [...data]
                    let _updated = _data.filter((e) => e.id != id)
                    setData(_updated)
                } else {
                    toast.error(res.message || "Some error Occurred!")
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setDeleating(null)
            })
    }

    const details = (e, ele) => {
        ele.preventDefault();
        if (isLogged) {
            navigate(`/models/${(e?.seo || 'model')}`)
        } else {
            navigate((e?.seo || 'model'))
        }
    }

    const onSort = (id, sort) => {

        let _data = [...data]
        let index = _data.findIndex(item => item.id == id)

        if ((index == 0 && sort == "up") || (index == (_data.length - 1) && sort == 'down')) {
            toast.error(`No item is ${sort == "down" ? "below" : "above"}!`)
            return
        }

        Api.post("user-category/" + id + "/item/re-order",
            {
                "category_id": parseInt(categoryTitle),
                "direction": sort
            })
            .then((res) => {
                if (res.success) {
                    if (sort == "up") {
                        let before = _data[index]
                        _data[index] = _data[index - 1]
                        _data[index - 1] = before
                    } else {
                        let before = _data[index]

                        _data[index] = _data[index + 1]
                        _data[index + 1] = before
                    }
                    // let updatedData = [...data]
                    // updatedData[0].character = _data

                    setData(_data)
                } else {
                    toast.error(res?.message || "Some Error occured!")
                }
            })
            .catch((err) => {
                toast.error(err?.message || "Some Error occured!")
            })
    }

    const onTermsChange = () => {
        setTermsError(false)
        setTermsAcceptance(!termsAccepted);
    }
    const onPublicChange = () => {
        setPublic(!isPublic);
    }

    const onFilterChange = (e) => {
        setSelectedFilter(e.target.value)
    }

    const onToggleChange = (val) => {
        setActiveType(val)
    }

    const handleaddItem = () => {
        setAddingItem(true)
    }

    const handle_photos = () => {

        setModel(false)
    }

    const selectItemPhoto = (k, v) => {

        // if (type == 'default') {
        let data = { ...itemPhoto }
        data[k] = v;
        setItemPhoto(data)
        // } else {
        //   let data = { ...itemPhoto }
        //   data['photo'] = v;
        //   setItemPhoto(data)
        // }

    }

    const hasItemImages = () => {
        let res = false
        imagesToSelect.map((item) => {
            if (item.category_id.includes(character.id)) {
                res = true
            }
        })

        return res
    }

    return (
        <div style={{ width: '90%', margin: 'auto', marginTop: '120px' }} >
            <>
                <Modal
                    show={modal}
                    centered
                >
                    {modal == "images" ?
                        <>
                            <Modal.Header>
                                <h4 style={{ fontFamily: 'Dimbo' }}>Select Image</h4>
                                <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                                    <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} style={{ cursor: 'pointer' }} />
                                </div>
                            </Modal.Header>
                            {character?.type && character.type == '2' ?
                                <div className='d-flex justify-content-around' >
                                    <div>
                                        <div className='text-center my-2' style={{ fontFamily: "Dimbo" }} >Healthy Image </div>
                                        {imagesToSelect.map((item, index) => {
                                            if (!item.category_id.includes(character.id)) return
                                            return (
                                                <div key={index} onClick={() => selectItemPhoto("healthy", item)} style={{ boxSizing: 'border-box' }} className={`rounded m-2 border border-2 ${itemPhoto?.healthy?.id == item.id ? "border-dark" : " border-white"}`}>
                                                    <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                                                </div>)
                                        }
                                        )}
                                    </div>
                                    <div>
                                        <div className='text-center my-2' style={{ fontFamily: "Dimbo" }} >Toxic Image </div>
                                        {imagesToSelect.map((item, index) => {
                                            if (!item.category_id.includes(character.id)) return
                                            return (<div key={index} onClick={() => selectItemPhoto("toxic", item)} style={{ boxSizing: 'border-box' }} className={`rounded m-2 border border-2 ${itemPhoto?.toxic?.id == item.id ? "border-dark" : " border-white"}`}>
                                                <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                                            </div>)
                                        }
                                        )}
                                        {!hasItemImages() && <div className='text-center py-2'>No image available</div>}
                                    </div>
                                </div>
                                :
                                <div>
                                    {imagesToSelect.map((item, index) => {
                                        if (!item.category_id.includes(character.id)) return
                                        else if (item.image) {
                                            return (
                                                <div key={index} onClick={() => selectItemPhoto("photo", item)} style={{ boxSizing: 'border-box', width: "68px" }} className={`rounded m-2 border border-2 m-auto ${itemPhoto?.photo?.id == item.id ? "border-dark" : " border-white"}`}>
                                                    <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                                                </div>
                                            )
                                        }

                                    })}
                                    {!hasItemImages() && <div className='text-center py-2' >No image available</div>}
                                </div>
                            }
                            <Modal.Footer>
                                <Buttons title={'Save'} onClick={handle_photos} style={{ height: '50px', lineHeight: '50px' }} />
                                {/* <Buttons title={'Create'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={savingCharacter} onClick={saveCharacter} /> */}
                            </Modal.Footer>
                        </>
                        :
                        <div>
                            <form >
                                <Modal.Header>
                                    <h4 style={{ fontFamily: 'Dimbo' }}>Add Item</h4>
                                    <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                                        <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} style={{ cursor: 'pointer' }} />
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* <h5 style={{ fontFamily: 'Dimbo' }} >Terms and conditions</h5> */}
                                    {termsErr && <div className="border border-danger rounded px-2 py-1 mb-2 text-danger">Please accept the Terms and Conditions!</div>}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CheckBox value={isPublic} onChange={onPublicChange} /> <div>Set as Public</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CheckBox value={termsAccepted} onChange={onTermsChange} /> <div>I accept the <a style={{ color: '#000' }} href="https://nestheads.com/term-of-use/" target='_blank'>Terms and Conditions</a>.</div>
                                    </div>
                                </Modal.Body>
                            </form>
                            <Modal.Footer>
                                <Buttons title={'Add Item'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={submitting} onClick={handleAddItem} />
                            </Modal.Footer>
                        </div>
                    }
                </Modal>
            </>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
                <div style={{ flex: 1 }} >
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
                        <Buttons title={'+ Add Item'} loading={false} onClick={handleaddItem} variant={addingItem ? "primary" : "transparent"} />
                    </div>
                </div>
                <div style={{ flex: 1 }} ></div>
                <div style={{ flex: 1 }} >
                    {/* <div className='charactor_filter_container' >
                    <div className='my-4 mx-2 text-right'>
                        <select className='character_filter' value={selectedFilter} style={{ fontFamily: 'Dimbo' }} title='Filter' onChange={onFilterChange}>
                            <option className='filter_options' value={'new'}>newest</option>
                            <option className='filter_options' value={'name'} >name</option>
                            <option className='filter_options' value={'popularity'}>popularity</option>
                        </select>
                    </div>
                </div> */}
                </div>
            </div>

            {addingItem &&
                (
                    character?.type == "2" ?
                        <div className='w-75 m-auto p-2 rounded my-5' style={{ backgroundColor: '#f7f7f7', border: '2px solid darkgray' }} >
                            <div className=' w-100 md-w-75 m-auto d-flex justify-content-around ' >
                                <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Toxic " + character?.name} variant={itemsdata?.toxic ? "primary" : 'transparent'} />
                                <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Healthy " + character?.name} variant={itemsdata?.healthy ? "primary" : 'transparent'} />
                            </div>
                            <div className='d-flex mb-2' >
                                <div onClick={handleItemImage} style={{ width: '50px', height: '50px' }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                                    <img src={itemPhoto?.healthy?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                </div>
                                <input onChange={(e) => handleChange("toxic", e.target.value)} value={itemsdata?.toxic || ""} className='border border-0 flex-1 bg-transparent text-dark w-100' placeholder='Toxic thought here' />
                            </div>
                            <div className='d-flex mb-2' >
                                <div onClick={handleItemImage} style={{ width: '50px', height: '50px' }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                                    <img src={itemPhoto?.toxic?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                </div>
                                <input onChange={(e) => handleChange("healthy", e.target.value)} value={itemsdata?.healthy || ''} className='border border-0 flex-1 bg-transparent text-dark w-100 ' placeholder='Healthy thought here' />
                            </div>
                            <div className='w-100 d-flex justify-content-between' >
                                <div className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                </div>
                                <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='w-75 m-auto p-2 rounded my-5' style={{ backgroundColor: '#f7f7f7', border: '2px solid darkgray' }} >
                            <div className=' w-100 md-w-75 m-auto d-flex justify-content-around ' >
                                <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={character.name} variant={itemsdata?.toxic ? "primary" : 'transparent'} />
                                {/* <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Healthy Thought"} variant={itemsdata?.healthy ? "primary" : 'transparent'} /> */}
                            </div>
                            <div className='d-flex mb-2' >
                                <div onClick={handleItemImage} style={{ width: '50px', height: '50px' }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                                    <img src={itemPhoto?.photo?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                </div>
                                <input onChange={(e) => handleChange("thought", e.target.value)} value={itemsdata?.thought || ""} className='border border-0 flex-1 bg-transparent text-dark w-100' placeholder={character.name + " here"} />
                            </div>
                            {/* <div className='d-flex mb-2' >
                    <div style={{ width: '50px', height: '50px' }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                      <img src={itemPhoto?.toxic?.img} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                    </div>
                    <input onChange={(e) => handleChange("healthy", e.target.value)} value={itemsdata?.healthy || ''} className='border border-0 flex-1 bg-transparent text-dark w-100 ' placeholder='Healthy thought here' />
                  </div> */}
                            <div className='w-100 d-flex justify-content-between' >
                                <div className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                </div>
                                <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                )
            }

            <div>
                {
                    loading ?
                        <div>
                            {character?.type == 2 ?
                                <div className='mb-2' style={{ display: 'flex', justifyContent: 'space-between', gap: 5 }}  >
                                    <div className='rounded p-3  d-flex ' style={{ flex: 1, backgroundColor: '#eee' }} >
                                        <div style={{ height: '96px' }} ></div>
                                    </div>
                                    <div className='rounded p-3  d-flex' style={{ flex: 1, backgroundColor: '#eee' }} >
                                        <div style={{ height: '96px' }}></div>
                                    </div>
                                </div>
                                :
                                <div className=' rounded p-3 mb-2 ' style={{ flex: 1, backgroundColor: '#eee' }} >
                                    <div style={{ height: '96px' }}></div>
                                </div>
                            }
                        </div>
                        :
                        <>
                            <div style={{ fontSize: '22px', fontFamily: "Dimbo" , margin: '20px 0px'}} >Your Category Items</div>
                            {
                                data?.length != 0 ?
                                    data.map((item) => {
                                        return (
                                            <>
                                                {
                                                    character?.type == 2 ?
                                                        <div className='mb-2' style={{ display: 'flex', justifyContent: 'space-between', gap: 5, width: '100%' }}  >
                                                            <div className='border border-2 rounded p-3  d-flex ' style={{ flex: 1, alignItems: 'center', width: "50%" }} >
                                                                <div style={{ marginRight: '4px' }}>
                                                                    <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "up")} >
                                                                        <Icon icon={arrowUp} />
                                                                    </div>
                                                                    <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "down")} >
                                                                        <Icon icon={arrowDown} />
                                                                    </div>
                                                                </div>
                                                                <div  className='d-flex justify-content-between w-100'>
                                                                    <div className='d-flex'>
                                                                        <div style={{ width: '22px', height: '24px', marginRight: 10 }} >
                                                                            <img src={item?.healthy_images?.[0]?.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                                        </div>
                                                                        <div>{item.description.healthy}</div>
                                                                    </div>
                                                                    <div onClick={() => handleDelete(item.id)} role='button' ><Icon icon={androidDelete} /></div>
                                                                </div>
                                                            </div>
                                                            <div className='border border-2 rounded p-3  d-flex' style={{ flex: 1, alignItems: 'center', width: "50%" }} >
                                                                <div style={{ marginRight: '4px' }}>
                                                                    <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "up")} >
                                                                        <Icon icon={arrowUp} />
                                                                    </div>
                                                                    <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "down")} >
                                                                        <Icon icon={arrowDown} />
                                                                    </div>
                                                                </div>
                                                                <div  className='d-flex justify-content-between w-100'>
                                                                    <div className='d-flex'>
                                                                        <div style={{ width: '22px', height: '24px', marginRight: 10 }} >
                                                                            <img src={item?.toxic_images?.[0]?.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                                        </div>
                                                                        <div>{item.description.toxic}</div>
                                                                    </div>
                                                                    <div onClick={() => handleDelete(item.id)} role='button' ><Icon icon={androidDelete} /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='border border-2 rounded p-3 mb-2 ' style={{ display: 'flex', alignItems: 'center' }} >
                                                            <div style={{ marginRight: '4px' }}>
                                                                <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "up")} >
                                                                    <Icon icon={arrowUp} />
                                                                </div>
                                                                <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "down")} >
                                                                    <Icon icon={arrowDown} />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-between w-100' >
                                                                <div className='d-flex' > 
                                                                    <div style={{ width: '22px', height: '24px', marginRight: 10 }} >
                                                                        <img src={item?.default_image?.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                                    </div>
                                                                    <div>{item.description.default}</div>
                                                                </div>
                                                                <div onClick={() => handleDelete(item.id)} role='button' ><Icon icon={androidDelete} /></div>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        )
                                    })
                                    : <div className='text-center' style={{ fontFamily: "Dimbo" }} >No Item </div>
                            }
                            {
                                <>
                                    <div style={{ marginTop: '50px', fontSize: '22px', marginBottom: '20px',  fontFamily: "Dimbo" }} >Category Items</div>
                                    {
                                        publicItems?.length > 0 ?
                                            publicItems.map((item) => {
                                                return (
                                                    <>
                                                        {
                                                            character?.type == 2 ?
                                                                <div className='mb-2' style={{ display: 'flex', justifyContent: 'space-between', gap: 5, width: '100%' }}  >
                                                                    <div className='border border-2 rounded p-3  d-flex ' style={{ flex: 1, alignItems: 'center', width: "50%" }} >
                                                                        {/* <div style={{ marginRight: '4px' }}>
                                                                <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "up")} >
                                                                    <Icon icon={arrowUp} />
                                                                </div>
                                                                <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "down")} >
                                                                    <Icon icon={arrowDown} />
                                                                </div>
                                                            </div> */}
                                                                        <div className='d-flex'>
                                                                            <div style={{ width: '22px', height: '24px', marginRight: 10 }} >
                                                                                <img src={item?.healthy_images?.[0]?.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                                            </div>
                                                                            <div>{item.description.healthy}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='border border-2 rounded p-3  d-flex' style={{ flex: 1, alignItems: 'center', width: "50%" }} >
                                                                        {/* <div style={{ marginRight: '4px' }}>
                                                                <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "up")} >
                                                                    <Icon icon={arrowUp} />
                                                                </div>
                                                                <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "down")} >
                                                                    <Icon icon={arrowDown} />
                                                                </div>
                                                            </div> */}
                                                                        <div className='d-flex'>
                                                                            <div style={{ width: '22px', height: '24px', marginRight: 10 }} >
                                                                                <img src={item?.toxic_images?.[0]?.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                                            </div>
                                                                            <div>{item.description.toxic}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='border border-2 rounded p-3 mb-2 ' style={{ display: 'flex', alignItems: 'center' }} >
                                                                    {/* <div style={{ marginRight: '4px' }}>
                                                            <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "up")} >
                                                                <Icon icon={arrowUp} />
                                                            </div>
                                                            <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSort(item.id, "down")} >
                                                                <Icon icon={arrowDown} />
                                                            </div>
                                                        </div> */}
                                                                    <div className='d-flex' >
                                                                        <div style={{ width: '22px', height: '24px', marginRight: 10 }} >
                                                                            <img src={item?.default_image?.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                                        </div>
                                                                        <div>{item.description.default}</div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                                )
                                            })
                                            :
                                            <div className='text-center' style={{ fontFamily: "Dimbo" ,marginBottom:'100px' }} >No Item </div>
                                    }
                                </>
                            }
                        </>
                }
            </div>
            {/* <ModelListing data={data} loading={loading} showAdd={false} onClick={details} onSort={onSort} type={activetype} showSorting={true} /> */}
        </div>
    )
}

export default SubCategory